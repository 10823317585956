export const Env = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
} as const;

export const getEnv = (): string => process.env.NODE_ENV || Env.DEVELOPMENT;

export const isEnv = (env: string) => getEnv() === env;

const getPaywall = (): string | null =>
  new URLSearchParams(window.location.search).get("paywall");

export const getCheckoutUrl = () =>
  `https://www.techleads.club/checkout/${getPaywall() || "inscricao-starter"}`;

export const getHotjar = () => ({
  siteId: 5026109,
  hotjarVersion: 6,
});
