import { FC, useEffect, useRef, useState } from "react";

const CROSS_ORIGIN_LOCATION_EVENT = 'tlc:checkout:location'

const isCheckoutUrl = (url: string): boolean => /(?:www\.)?techleads\.club\/checkout\/.*/.test(url)

const isHomeUrl = (url: string): boolean => /(?:www\.)?techleads\.club\/home/.test(url)

// A post URL in Circle always has the path `/c/` on it.
const isPost = (url: string): boolean => /(?:www\.)?techleads\.club\/c\//.test(url)

// If the iframe href doesn't match the checkout URL but matches the community
// home or any post URL, it means the Circle checkout confirmation is done.
const isCircleCheckoutDone = (url: string): boolean => !isCheckoutUrl(url) && (isHomeUrl(url) || isPost(url))

export type EmbedCheckoutProps = {
  src: string
  minWidth: number
  minHeight: number
}

export const EmbedCheckout: FC<EmbedCheckoutProps> = ({ src, minHeight, minWidth }) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [size, setSize] = useState({
    height: `${minHeight}px`,
    width: `${minWidth}px`
  });

  const onLoad = () => {
    if (ref.current) {

      setSize({
        height: ref.current.clientHeight + 'px',
        width: ref.current.clientWidth + 'px'
      })
    }
  };

  const handleMessage = (event: MessageEvent) => {
    if (event.data.type === CROSS_ORIGIN_LOCATION_EVENT) {
      const url = event.data.href

      if (isCircleCheckoutDone(url)) {
        // Redirects the user to the same URL in the iframe.
        window.location.href = url
      }
    }
  };

  useEffect(() => {
    onLoad();

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <iframe
      ref={ref}
      data-hj-allow-iframe=""
      title={'Tech Leads club - Embed Checkout'}
      src={src}
      width={size.width}
      height={size.height}
      style={{
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        border: 'none'
      }}
    />
  )
}
