import React, { useEffect } from 'react';
import './App.css';
import { EmbedCheckout } from './EmbedCheckout';
import { getCheckoutUrl, getHotjar } from './config';
import Hotjar from '@hotjar/browser';

function App() {
  useEffect(() => {
    const { siteId, hotjarVersion } = getHotjar()

    Hotjar.init(siteId, hotjarVersion);
  }, [])

  return (
    <div className="App">
      <EmbedCheckout
        minWidth={800}
        minHeight={600}
        src={getCheckoutUrl()}
      />
    </div>
  );
}

export default App;
